import React from 'react';
import classNames from 'classnames';
import { Triangle } from 'react-feather';
import { IUseTableSorting } from 'client/hooks/useTableSorting';
import { IUseTablePagination } from 'client/hooks/useTablePagination';
import { Card, Form, FormGroupProps, InputGroup, InputGroupProps } from 'react-bootstrap';
import Pagination from 'client/table/TablePagination';
import NumberFormat from 'client/components/NumberFormat';
import OverlaySpinner from 'client/spinners/OverlaySpinner';
import Pluralizer from 'client/components/Pluralizer';

export interface TableOrderByProps extends IUseTableSorting {
  title: React.ReactNode;
  column: string;
}

export function TableOrderBy (props: TableOrderByProps) {
  const {
    column,
    title,
    isOrderedBy,
    isAsc,
    isDesc,
    toggleDirection,
    onSetOrder,
  } = props;
  const isActive = isOrderedBy(column);

  const onClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (isOrderedBy(column)) {
      toggleDirection();
    } else {
      onSetOrder(column);
    }
  };

  return (
    <button
      type="button"
      className="d-flex align-items-center gap-1 btn-reset"
      onClick={onClick}
      style={{fontWeight: 'bold'}}
    >
      <p className="m-0">{title}</p>
      <div>
        <Triangle
          size={10}
          className={classNames('d-block', { 'fill-base': isActive && isAsc })}
          role="button"
        />
        <Triangle
          size={10}
          className={classNames('d-block vertical-flip', { 'fill-base': isActive && isDesc })}
          role="button"
        />
      </div>
    </button>
  );
}

interface TableSpinningOverlayProps extends React.PropsWithChildren {
  isLoading: boolean;
}
export function TableSpinningOverlay (props: TableSpinningOverlayProps) {
  const { isLoading, children } = props;
  return (
    <div className="position-relative">
      {isLoading && (
        <OverlaySpinner isLoading={isLoading} />
      )}
      {children}
    </div>
  );
}

interface TableCardFooterProps extends React.PropsWithChildren {

}

export function TableCardFooter (props: TableCardFooterProps) {
  const { children } = props;
  return (
    <Card.Footer className="border-top-0 p-3 d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap">
      {children}
    </Card.Footer>
  );
}

interface TableCardFooterWithPaginationProps {
  tablePagination?: IUseTablePagination;
  rowLength: number;
}

export function TableCardFooterWithPagination (props: TableCardFooterWithPaginationProps) {
  const { tablePagination, rowLength } = props;
  return (
    <TableCardFooter>
      <p className="mb-sm-0">
        Totalt <NumberFormat value={tablePagination?.totalCountOfRows || rowLength} />{' '}
        <Pluralizer
          count={tablePagination?.totalCountOfRows || rowLength}
          zero="rader"
          one="rad"
          more="rader"
        /> i denna tabell
      </p>
      {tablePagination && (
        <Pagination withRowsPerPage {...tablePagination} />
      )}
    </TableCardFooter>
  );
}

interface TableFilterInputGroupProps extends FormGroupProps {
  name: string;
  label: React.ReactNode;
  size?: InputGroupProps['size'];
}

export function TableFilterInputGroup (props: TableFilterInputGroupProps) {
  const { label, children, size, ...restOfProps } = props;
  return (
    <Form.Group {...restOfProps}>
      <InputGroup size={size}>
        <InputGroup.Text>{label}</InputGroup.Text>
        {children}
      </InputGroup>
    </Form.Group>
  );
}
