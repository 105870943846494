import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface PageHeaderBreadcrumb {
  title: string;
  url?: string;
}

interface PageHeaderProps extends React.PropsWithChildren {
  breadcrumbs?: PageHeaderBreadcrumb[];
}

const PageHeader: React.FC<PageHeaderProps> = React.memo(function PageHeader (props: PageHeaderProps) {
  const { children, breadcrumbs } = props;
  return (
    <div className="mb-4">
      <h2 className="d-flex align-items-center mb-1 justify-content-between justify-content-sm-between gap-2 flex-wrap">
        {children}
      </h2>
      {breadcrumbs?.length && (
        <Breadcrumb>
          {breadcrumbs.map(({ url, title }, index) => (
            <Breadcrumb.Item
              key={index}
              linkAs={Link}
              linkProps={{to: url}}
              active={!url}
            >
              {title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
});

export default PageHeader;
