import React from 'react';
import useAuth from 'client/hooks/useAuth';
import { Col, Row, Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardReportQueueStatisticCards from 'client/dashboard/DashboardReportQueueStatisticCards';
import DashboardReportQueueErrorTableCard from 'client/dashboard/DashboardReportQueueErrorTableCard';
import DashboardCreatedObjectsBarChartCard from 'client/dashboard/DashboardCreatedObjectsBarChartCard';
import DashboardCreditsafeStatisticCards from 'client/dashboard/DashboardCreditsafeStatisticCards';
import RoleGuard from 'client/guards/RoleGuard';
import PageHeader from 'client/components/PageHeader';
import DashboardFortnoxTokenStatusCard from 'client/dashboard/DashboardFortnoxTokenStatusCard';

export default function DashboardPage () {
  const auth = useAuth();
  return (
    <Container className="p-4" fluid="sm">
      <PageHeader>
        Välkommen{auth.user?.name ? `, ${auth.user.name}` : ''}!
      </PageHeader>
      <Card className="d-flex flex-row justify-content-start p-3 gap-3 mb-4 flex-wrap">
        <Link className="btn btn-outline-primary btn-sm" to="/valuation_reports/create">
          Ny företagsvärdering &raquo;
        </Link>
        <Link className="btn btn-outline-primary btn-sm" to="/industry_reports/create">
          Ny branschrapport &raquo;
        </Link>
        <Link className="btn btn-outline-primary btn-sm" to="/report_queue/create">
          Ny företagsvärdering + branschrapport &raquo;
        </Link>
      </Card>
      <Row>
        <h3>Rapportstatus</h3>
        <DashboardReportQueueStatisticCards />
      </Row>
      <Row>
        <Col md={12} lg={6}>
          <DashboardCreatedObjectsBarChartCard
            title="Nya kunder"
            dataUrl="/api/dashboard/chart/customers"
          />
        </Col>
        <Col md={12} lg={6}>
          <DashboardCreatedObjectsBarChartCard
            title="Nya rapporter"
            dataUrl="/api/dashboard/chart/report_queues"
          />
        </Col>
      </Row>
      <RoleGuard role={['admin', 'coordinator']}>
        <div className="mt-4">
          <DashboardCreditsafeStatisticCards />
        </div>
      </RoleGuard>
      <RoleGuard role="admin">
        <DashboardFortnoxTokenStatusCard />
      </RoleGuard>
      <div className="mt-4">
        <DashboardReportQueueErrorTableCard />
      </div>
    </Container>
  );
}
