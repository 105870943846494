import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useHistory } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { Card, Container } from 'react-bootstrap';
import { IUser, TUserRole } from 'client/user/types';
import UserForm, { CreateUserFormFields } from 'client/user/UserForm';
import { Helmet } from 'react-helmet';
import SaveButton from 'client/buttons/SaveButton';
import { maybeAxiosErrorToErrorMap } from 'client/utils/errors';
import PageHeader from 'client/components/PageHeader';

interface ICreateUser {
  id: string;
  name: string;
  email: string;
  role: TUserRole;
  ip_addresses_allowed: string[];
  password: string;
}

interface IUserCreatePage { }
const UserCreatePage: React.FC<IUserCreatePage> = React.memo(function UserCreatePage () {
  const history = useHistory();

  const createUserMutation = useMutation<IUser, Error, ICreateUser>({
    mutationFn: vars => axios.post('/api/users', vars).then(r => r.data),
    onSuccess: user => {
      requestCallbacks.onSuccess('Användaren har skapats');
      history.push(`/user/${user.id}/form`);
    },
  });

  const onSubmit = (form: CreateUserFormFields, helpers: FormikHelpers<CreateUserFormFields>) => {
    const { ip_addresses_allowed, ...restOfForm } = form;
    const create = {...restOfForm} as ICreateUser;
    create.ip_addresses_allowed = ip_addresses_allowed ? ip_addresses_allowed.split(',') : [];
    createUserMutation.mutateAsync(create).catch(err => {
      helpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err);
      if (errorMap) {
        helpers.setErrors(errorMap);
        return;
      }
      throw err;
    });
  };

  const initialValues = {
    id: '',
    name: '',
    email: '',
    role: 'noone' as TUserRole,
    ip_addresses_allowed: '',
    password: '',
  };

  return (
    <Container fluid="sm" className="p-4">
      <Helmet>
        <title>Ny användare</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'Användare', url: '/users'}, {title: 'Ny användare'}]}>
        Ny användare
      </PageHeader>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {formikBag => (
          <FormikForm>
            <Card>
              <Card.Body className="pb-1">
                <UserForm formikBag={formikBag} isCreateForm />
                <ErrorAlert error={createUserMutation.error} />
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end gap-2 p-2">
                <SaveButton
                  type="submit"
                  isLoading={createUserMutation.isPending}
                  disabled={!formikBag.isValid || formikBag.isSubmitting || !Object.keys(formikBag.touched).length}
                  buttonLabel="Spara"
                />
              </Card.Footer>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </Container>
  );
});
export default UserCreatePage;
