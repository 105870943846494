import React, { useCallback } from 'react';
import ReportQueueTableHandledFilterForm from 'client/reportQueue/ReportQueueTableHandledFilterForm';
import { IFilterParams } from 'client/reportQueue/ReportQueueTablePage';
import ReportQueueTable, { ReportQueueTableProps } from 'client/reportQueue/ReportQueueTable';

interface ReportQueueHandledTableProps extends Omit<ReportQueueTableProps, 'variant'> {

}

const ReportQueueHandledTable: React.FC<ReportQueueHandledTableProps> = React.memo(function ReportQueueHandledTable (props: ReportQueueHandledTableProps) {
  const { state, setStateMap } = props;
  const { filterParamsHandled } = state;

  const setFilterParams = useCallback((filterParamsHandled: React.SetStateAction<IFilterParams>) => {
    setStateMap({filterParamsHandled});
  }, [setStateMap]);

  return (
    <>
      <ReportQueueTableHandledFilterForm
        filterParams={filterParamsHandled}
        setFilterParams={setFilterParams}
      />
      <ReportQueueTable
        {...props}
        variant="ready_done"
      />
    </>
  );
});

export default ReportQueueHandledTable;
