import React from 'react';
import useAuth from 'client/hooks/useAuth';

interface HasCompanyMonitoringGuardProps extends React.PropsWithChildren {
}

const HasCompanyMonitoringGuard: React.FC<HasCompanyMonitoringGuardProps> = React.memo(function HasCompanyMonitoringGuard (props: HasCompanyMonitoringGuardProps) {
  const { children } = props;
  const auth = useAuth();

  if (typeof auth.customer?.company_monitored_limit === 'number') {
    return (
      <>{children}</>
    );
  }

  if (typeof auth.user?.company_monitored_limit === 'number') {
    return (
      <>{children}</>
    );
  }

  return null;
});

export default HasCompanyMonitoringGuard;
