import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'client/contexts/AuthContext';
import AuthLoaderGuard from 'client/guards/AuthLoaderGuard';
import Helmet from 'react-helmet';
import axios from 'client/axios';
import Router from 'client/router/Router';
import { ToastContainer } from 'react-toastify';
import Navbar from 'client/navbar/Navbar';
import { ErrorBoundary } from 'react-error-boundary';
import AppError from 'client/components/AppError';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import 'client/assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 0,
      gcTime: 0,
      refetchOnWindowFocus: false,
      queryFn: async (context) => {
        const {queryKey, signal, meta} = context;
        if (typeof queryKey[0] !== 'string') return null;
        const [url, params] = queryKey;
        const response = await axios.get(url, {params, signal});

        if (typeof meta?.responseExtractor === 'function') {
          return meta.responseExtractor(response);
        }
        return response.data;
      },
    },
  },
});

export default function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <Helmet titleTemplate="%s | Calculate" />
      <BrowserRouter>
        <AuthProvider>
          <AuthLoaderGuard>
            <MainWrapper>
              <ErrorBoundary FallbackComponent={AppError}>
                <DndProvider backend={HTML5Backend}>
                  <Router />
                </DndProvider>
              </ErrorBoundary>
            </MainWrapper>
          </AuthLoaderGuard>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

const MainWrapper: React.FC<React.PropsWithChildren> = React.memo(function MainWrapper (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <div>
      <Navbar />
      <ToastContainer position="bottom-right"/>
      {children}
    </div>
  );
});
