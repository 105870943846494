import React from 'react';
import { Field, FormikProps } from 'formik';
import PasswordFormControl, { PasswordFormText, passwordRegexp, PasswordRandomButton } from 'client/form/PasswordFormControl';
import IdProvider from 'client/components/IdProvider';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import { Card, Collapse, Form } from 'react-bootstrap';
import classNames from 'classnames';

export type AccountChangePasswordFormFieldsType = {
  change_password: boolean;
  password: string;
  password_repeat: string;
  [key: string]: any;
};

interface IAccountChangePasswordFieldsForm {
  disabled?: boolean;
  checkboxLabel?: string;
  isOwnPassword?: boolean; // if you are changing your own password, always hide it and no random button required
  formikBag: FormikProps<AccountChangePasswordFormFieldsType>;
}

const AccountChangePasswordFormFields: React.FC<IAccountChangePasswordFieldsForm> = React.memo(function AccountChangePasswordFormFields (props: IAccountChangePasswordFieldsForm) {
  const {
    formikBag,
    isOwnPassword = false,
    checkboxLabel = 'Ändra lösenord',
    disabled = false,
  } = props;
  return (
    <Card className="mb-3">
      <Card.Header className={classNames('py-3 border-bottom-0', {
        'card-header-rounded': !formikBag.values.change_password,
      })}> 
        <IdProvider>
          {id => (
            <Form.Group>
              <Field
                className="mb-0"
                as={Form.Check}
                disabled={disabled}
                checked={formikBag.values.change_password}
                id={id}
                name="change_password"
                label={checkboxLabel}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Card.Header>
      <Collapse in={formikBag.values.change_password} unmountOnExit>
        <div>
          <Card.Body className="pb-0 border-top">
            <IdProvider>
              {id => (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor={id}>
                    Nytt lösenord
                  </Form.Label>
                  <div className="d-flex gap-2 flex-wrap">
                    <Field
                      as={PasswordFormControl}
                      isOwnPassword={isOwnPassword}
                      id={id}
                      disabled={disabled || !formikBag.values.change_password}
                      type="password"
                      name="password"
                      minLength="8"
                      placeholder="Ange det nya lösenordet"
                      isInvalid={formikBag.values.change_password && Boolean(formikBag.errors.password)}
                      isValid={formikBag.values.change_password && !formikBag.errors.password && formikBag.touched.password}
                      required
                    />
                    {!isOwnPassword && (
                      <PasswordRandomButton
                        onGenerated={password => formikBag.setFieldValue('password', password)}
                      />
                    )}
                  </div>
                  <PasswordFormText />
                </Form.Group>
              )}
            </IdProvider>
            {isOwnPassword && (
              <IdProvider>
                {id => (
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor={id}>
                      Repetera lösenordet
                    </Form.Label>
                    <Field
                      as={PasswordFormControl}
                      id={id}
                      isOwnPassword={isOwnPassword}
                      type="password"
                      minLength="8"
                      disabled={disabled || !formikBag.values.change_password}
                      name="password_repeat"
                      placeholder="Ange det nya lösenordet igen"
                      isInvalid={formikBag.values.change_password && Boolean(formikBag.errors.password_repeat)}
                      isValid={formikBag.values.change_password && !formikBag.errors.password_repeat && formikBag.touched.password_repeat}
                      required
                    />
                    <ErrorFormControlFeedback
                      error={formikBag.errors.password_repeat}
                      touched={formikBag.touched.password_repeat}
                    />
                  </Form.Group>
                )}
              </IdProvider>
            )}

          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
});

export default AccountChangePasswordFormFields;

export function validateAccountChangePasswordFormFields (values: AccountChangePasswordFormFieldsType) {
  const errors: any = {};
  const { change_password, password, password_repeat } = values;

  if (change_password) {
    if (!passwordRegexp.test(password)) {
      errors.password = true;
    }
    if (password && password !== password_repeat) {
      errors.password_repeat = 'Lösenordet stämmer inte';
    }
  }

  return errors;
}
