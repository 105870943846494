import React from 'react';
import { useField } from 'formik';
import {
  Form,
  FormCheckProps,
  FormControlProps,
  FormSelectProps,
  InputGroup as BsInputGroup,
} from 'react-bootstrap';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import { inputPropsToFormikValidate, selectPropsToFormikValidate } from 'client/utils/form';

interface InputProps extends FormControlProps {
  label?: React.ReactNode;
  labelClassName?: string;
  className?: string;
  name: string;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  step?: number | string;
  min?: number | string;
  max?: number | string;
  rows?: number | string;
  helpText?: React.ReactNode;
}

// combines react-bootstrap Form.Group, Form.Feedback, Form.Control and formik Field
// must not always be used but can be useful for setting up a generic form with error validation
// think CAREFULLY before extending this component, it might be better to create a custom one
export const Input: React.FC<InputProps> = React.memo(function Input (props: InputProps) {
  const { name, type, className, labelClassName = 'mb-1 mt-3', label, helpText, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className={labelClassName} htmlFor={id}>
          {label}
        </Form.Label>
      )}
      <Form.Control
        {...restOfProps}
        {...field}
        id={id}
        name={name}
        type={type}
        isInvalid={Boolean(meta.error)}
      />
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
      {helpText && (
        <Form.Text>{helpText}</Form.Text>
      )}
    </Form.Group>
  );
});

interface TextareaProps extends InputProps {
}

// see note on Input
export const Textarea: React.FC<TextareaProps> = React.memo(function Textarea (props: TextareaProps) {
  return (
    <Input {...props} as="textarea" />
  );
});

interface SelectProps extends FormSelectProps, React.PropsWithChildren {
  label?: React.ReactNode;
  className?: string;
  labelClassName?: string;
  name: string;
  required?: boolean;
}

// see note on Input
export const Select: React.FC<SelectProps> = React.memo(function Select (props: SelectProps) {
  const { name, children, className, labelClassName = 'mb-1 mt-3', label, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    validate: selectPropsToFormikValidate(props),
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className={labelClassName} htmlFor={id}>
          {label}
        </Form.Label>
      )}
      <Form.Select
        {...restOfProps}
        {...field}
        id={id}
        name={name}
        isInvalid={Boolean(meta.error)}
      >
        {children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

interface CheckProps extends FormCheckProps {
  name: string;
  value?: string;
  type?: FormCheckType;
  className?: string;
}
// see note on FormikFormGroupControl
export const Check: React.FC<CheckProps> = React.memo(function Check (props: CheckProps) {
  const { name, value, type, className, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    value,
    type,
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      <Form.Check
        {...restOfProps}
        {...field}
        type={type}
        id={id}
        name={name}
        isInvalid={Boolean(meta.error)}
      />
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

interface InputGroup extends InputProps {
  labelClassName?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
  className?: string;
}
// see note on FormikFormGroupControl
export const InputGroup: React.FC<InputGroup> = React.memo(function InputGroup (props: InputGroup) {
  const { name, label, className, labelClassName = 'mb-1 mt-3', type, before, after, ...restOfProps } = props;

  const [field, meta] = useField({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
  });

  const id = React.useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className={labelClassName} htmlFor={id}>
          {label}
        </Form.Label>
      )}
      <BsInputGroup hasValidation>
        {before}
        <Form.Control
          {...restOfProps}
          {...field}
          type={type}
          id={id}
          name={name}
          isInvalid={Boolean(meta.error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      </BsInputGroup>
    </Form.Group>
  );
});
