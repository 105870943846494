import React from 'react';
import {
  AsCustomerIndustryReportTable,
  AsCustomerValuationReportTable,
} from 'client/asCustomer/AsCustomerReportTables';
import { Helmet } from 'react-helmet';
import AsCustomerCompanyMonitoringCard from 'client/asCustomer/AsCustomerCompanyMonitoringCard';
import { Container } from 'react-bootstrap';
import useAuth from 'client/hooks/useAuth';

export default function AsCustomerDashboardPage () {
  const auth = useAuth();
  const hasMonitoringAccess = typeof auth.customer?.company_monitored_limit === 'number';
  return (
    <Container fluid="lg" className="p-4">
      <Helmet>
        <title>Rapporter</title>
      </Helmet>
      <h2>Välkommen till Calculates kundportal!</h2>
      <p className="mb-0">Här nedan ser ni era beställda rapporter.</p>
      <AsCustomerValuationReportTable className="mt-4" />
      <AsCustomerIndustryReportTable className="mt-4" />
      {hasMonitoringAccess && (
        <AsCustomerCompanyMonitoringCard className="mt-4" />
      )}
    </Container>
  );
}
