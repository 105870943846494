import React, { useMemo } from 'react';
import { Spinner, Table as BsTable, TableProps as BsTableProps } from 'react-bootstrap';
import { ColumnDefinition, GenericRow } from 'client/table/types';
import { keyBy } from 'lodash';

export interface TableVirtualProps<RowType extends GenericRow = GenericRow> extends BsTableProps {
  rows?: null | RowType[];
  columns: ColumnDefinition<RowType>[];
  columnOrder?: string[];
  isInitialLoading?: boolean;
  emptyMessage?: string;
}

export default React.memo(TableVirtual) as typeof TableVirtual;

function TableVirtual<RowType extends GenericRow> (props: TableVirtualProps<RowType>) {
  const {
    columns,
    columnOrder:columnOrderOuter,
    rows,
    isInitialLoading = false,
    emptyMessage = 'Tabellen är tom',
    ...bsTableProps
  } = props;

  const columnOrder = useMemo(() => {
    if (!columnOrderOuter) return columns.map(column => column.id);
    return columnOrderOuter;
  }, [columnOrderOuter, columns]);

  const columnsById = useMemo(() => {
    return keyBy(columns, 'id');
  }, [columns]);

  return (
    <BsTable {...bsTableProps}>
      <thead>
        <tr>
          {columnOrder.map(columnId => (
            <TableTh key={columnId} column={columnsById[columnId]} />
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.isArray(rows) && rows.length > 0 ? (
          <>
            {rows.map((row, index) => (
              <tr key={typeof row.id !== 'undefined' ? row.id : index}>
                {columnOrder.map(columnId => (
                  <TableTd<RowType>
                    key={columnId}
                    column={columnsById[columnId]}
                    row={row}
                  />
                ))}
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={columns.length}>
              {isInitialLoading ? (
                <span className="d-inline-flex gap-2 align-items-center">
                  <Spinner variant="primary" size="sm" />
                  <span className="text-primary">Laddar data…</span>
                </span>
              ) : (
                <span>{emptyMessage}</span>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </BsTable>
  );
}

interface TableThProps<RowType extends GenericRow> {
  column: ColumnDefinition<RowType>;
}

// TODO titles could dotted underline the th label, and show a tooltip on hover
function TableTh<RowType extends GenericRow> (props: TableThProps<RowType>) {
  const { column } = props;
  const { id, title, label, Head } = column;
  if (Head) return <th>{Head && <Head id={id} label={label} title={title} />}</th>;
  if (label) return <th title={title}>{label}</th>;
  return <th title={title}>{id}</th>;
}

interface TableTdProps<RowType extends GenericRow> {
  column: ColumnDefinition<RowType>;
  row: RowType;
}

function TableTd<RowType extends GenericRow> (props: TableTdProps<RowType>) {
  const { column, row } = props;
  const { Body } = column;
  return (
    <td>
      <Body row={row} />
    </td>
  );
}
