import React from 'react';
import { Card , Container} from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { SniCodeRow } from 'client/sni/types';
import SniCodeTable from 'client/sni/SniCodeTable';
import Pluralizer from 'client/components/Pluralizer';
import RefreshButton from 'client/buttons/RefreshButton';
import PageHeader from 'client/components/PageHeader';

const SniCodeTablePage: React.FC = React.memo(function SniCodeTablePage () {

  const query = useQuery<SniCodeRow[], Error>({
    queryKey: ['/api/sni'],
  });

  const list = query.data ?? [];

  return (
    <Container fluid="lg" className="p-4">
      <Helmet>
        <title>SNI-koder</title>
      </Helmet>
      <PageHeader>SNI-koder</PageHeader>
      <ErrorAlert className="my-3" error={query.error} />
      <Card>
        <Card.Header className="d-flex flex-wrap gap-3 justify-content-between align-items-center">
          <span>
            Totalt {list.length}{' '}
            <Pluralizer
              count={list.length}
              zero="rader"
              one="rad"
              more="rader"
            /> i denna tabell
          </span>
          <RefreshButton
            className="px-2"
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
          />
        </Card.Header>
        <SniCodeTable
          list={list}
          isLoading={query.isLoading || query.isRefetching}
          isSuccess={query.isSuccess}
        />
      </Card>
    </Container>
  );
});

export default SniCodeTablePage;

