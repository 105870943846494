import React from 'react';
import { ColumnDefinition } from 'client/table/types';
import { Form } from 'react-bootstrap';
import { IUseTableSelectRows } from 'client/hooks/useTableSelectRows';
import { TableOrderBy } from 'client/table/TableUtils';
import { IUseTableSorting } from 'client/hooks/useTableSorting';
import InspectObjectModalButton, { InspectObjectModalButtonProps } from 'client/buttons/InspectObjectModalButton';
import DateFormat from 'client/components/DateFormat';
import NumberFormat from 'client/components/NumberFormat';
import { useMaybeTableContext } from 'client/contexts/TableContext';
import {NumberFormatType} from 'client/utils/number';

// [id], [id, label], [id, label, title]
type ColumnIdentifier = [string] | [string, string] | [string, string, string];

// use if you want to provide just a key to show as a string (most common type of column)
export const simple = (identifier: ColumnIdentifier, extendWith = {}): ColumnDefinition<any> => {
  const [id, label, title] = identifier;
  return ({
    id,
    label,
    title,
    Body: props => (
      <span>{props.row[id as keyof typeof props.row]}</span>
    ),
    ...extendWith,
  });
};

// use if you just want to provide id, label and Body (probably most common type of custom column)
export const cell = (identifier: ColumnIdentifier, Body: ColumnDefinition<any>['Body'], extendWith = {}): ColumnDefinition<any> => {
  const [id, label, title] = identifier;
  return ({
    id,
    label,
    title,
    Body,
    ...extendWith,
  });
};

export const select = (outerTableSelectRows?: IUseTableSelectRows): ColumnDefinition<any> => ({
  id: 'select',
  label: 'Välj',
  Head: () => {
    const tableSelectRows = (useMaybeTableContext()?.tableSelectRows || outerTableSelectRows) as IUseTableSelectRows;
    return (
      <Form.Check
        ref={(node: HTMLInputElement) => {
          if (!node) return;
          node.indeterminate = tableSelectRows.allRowSelectedIndeterminated;
        }}
        checked={tableSelectRows.allRowSelectedChecked}
        onChange={tableSelectRows.onChangeSelectAllRowsInput}
        name="select_all"
      />
    );
  },
  Body: (props) => {
    const tableSelectRows = (useMaybeTableContext()?.tableSelectRows || outerTableSelectRows) as IUseTableSelectRows;
    return (
      <Form.Check
        value={props.row.id}
        checked={tableSelectRows.selectedRows[props.row.id] === true}
        onChange={tableSelectRows.onChangeSelectRowInput}
        name="select"
      />
    );
  },
});

// turns a normal column into a sortable column by replacing Head
export const sortable = (def: ColumnDefinition<any>, outerTableSort?: IUseTableSorting) => ({
  ...def,
  Head: () => {
    const tableSort = (useMaybeTableContext()?.tableSort || outerTableSort) as IUseTableSorting;
    return (
      <TableOrderBy
        {...tableSort}
        column={def.meta?.sortKey || def.id}
        title={def.label}
      />
    );
  },
});

export const actions = (actions?: ((props: any) => React.ReactNode), inspectObjectModalButtonProps?: Partial<InspectObjectModalButtonProps>): ColumnDefinition<any> => ({
  id: 'actions',
  label: 'Åtgärd',
  Head: () => <span className="text-end d-block">Åtgärd</span>,
  Body: props => (
    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
      {actions && actions(props)}
      <InspectObjectModalButton object={props.row} {...inspectObjectModalButtonProps} />
    </div>
  ),
});

export const date = (identifier: ColumnIdentifier, format = 'YYYY-MM-DD', extendWith = {}): ColumnDefinition<any> => {
  const [id, label, title] = identifier;
  return {
    id,
    label,
    title,
    Body: props => (
      <DateFormat value={props.row[id as keyof typeof props.row]} format={format} />
    ),
    ...extendWith,
  };
};

export const number = (identifier: ColumnIdentifier, format: NumberFormatType = 'number', extendWith = {}): ColumnDefinition<any> => {
  const [id, label, title] = identifier;
  return {
    id,
    label,
    title,
    Body: props => (
      <NumberFormat value={props.row[id as keyof typeof props.row]} format={format} />
    ),
    ...extendWith,
  };
};
