import { useMemo, useCallback, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { ICsImported } from 'client/cs/types';
import CsImportedTableFilterForm from 'client/cs/CsImportedTableFilterForm';
import PageHeader from 'client/components/PageHeader';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import * as commonColumnDefinitions from 'client/table/commonColumnDefinitions';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import DateFormat from 'client/components/DateFormat';
import { ColumnDefinition } from 'client/table/types';
import { TableProvider } from 'client/contexts/TableContext';

export interface CsImportedTableFilterParams {
  search_term?: string;
}

interface ListData {
  rows: ICsImported[];
  total_rows: number;
}

const defaultRows: ListData['rows'] = [];
const defaultFilterParams = {};
const defaultState = {
  orderBy: 'zip_date',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CsImportedTablePage () {
  const {
    setStateMap,
    params,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
  } = useTableState({
    defaultState,
  });

  const listQuery = useQuery<ListData>({
    queryKey: ['/api/cs/imported/list', params],
    placeholderData: keepPreviousData,
  });
  const rows = listQuery.data?.rows ?? defaultRows;

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(listQuery.data?.total_rows ?? 0);
  }, [listQuery.data?.total_rows]);

  const onRefetch = useCallback(() => {
    listQuery.refetch();
  }, [listQuery.refetch]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);

  return (
    <Container fluid="lg" className="p-4">
      <TableProvider tableSort={tableSort}>
        <Helmet>
          <title>Creditsafe-importer</title>
        </Helmet>
        <PageHeader>Creditsafe-importer</PageHeader>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <CsImportedTableFilterForm
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterParams={filterParams}
              setFilterParams={setStateMap}
              onRefetch={onRefetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </Container>
  );
}

const defaultTableColumns = [
  'zip_name',
  'zip_type',
  'zip_date',
  'created_at',
  'actions',
];

const columnSortable = [
  'zip_name',
  'zip_type',
  'zip_date',
  'created_at',
];

type ColumnDefinitionFactory = () => ColumnDefinition<any>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  commonColumnDefinitions.simple(['zip_name', 'Filnamn']),
  commonColumnDefinitions.cell(['zip_type', 'Filtyp'], props => (
    props.row.zip_type.toUpperCase()
  )),
  commonColumnDefinitions.cell(['zip_date', 'Fildatum'], props => (
    <DateFormat value={props.row.zip_date} format="YYYY-MM-DD" />
  )),
  commonColumnDefinitions.cell(['created_at', 'Importerad'], props => (
    <DateFormat value={props.row.created_at} format="YYYY-MM-DD" />
  )),
  commonColumnDefinitions.actions(),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? commonColumnDefinitions.sortable(obj) : obj);
