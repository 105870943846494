import React from 'react';

interface PluralizerProps {
  count?: number;
  zero?: React.ReactNode;
  one?: React.ReactNode;
  more?: React.ReactNode;
}

const Pluralizer: React.FC<PluralizerProps> = React.memo(function Pluralizer (props: PluralizerProps) {
  const { count, zero, one, more } = props;
  if (typeof count !== 'number' || !isFinite(count) || isNaN(count)) return null;
  if (!count) return <>{zero}</>;
  if (count === 1) return <>{one}</>;
  return <>{more}</>;
});

export default Pluralizer;
