import { IdShort } from 'client/components/MiscFormatters';
import Anchor from 'client/anchors/Anchor';

interface CompanyMonitoredUpdateAnchorProps {
  value: string;
}

export function CompanyMonitoredUpdateAnchor (props: CompanyMonitoredUpdateAnchorProps) {
  const { value:companyMonitoredUpdateOrgNumber } = props;
  return (
    <Anchor to={`/company/${companyMonitoredUpdateOrgNumber}/overview`}>
      <IdShort value={companyMonitoredUpdateOrgNumber} />
    </Anchor>
  );
}

interface CompanyMontoredUpdateChangeCodeProps {
  value: null | string;
}

export function CompanyMontoredUpdateChangeCode (props: CompanyMontoredUpdateChangeCodeProps) {
  const { value } = props;
  if (value === null) return null;
  switch (value) {
    default: return <span>{value}</span>;
    case 'ADR': return <span>Adress</span>;
    case 'ALC': return <span>Serveringstillstånd</span>;
    case 'ANA': return <span>Bokslut</span>;
    case 'ANR': return <span>Helårsredovisning (PDF)</span>;
    case 'ASI': return <span>Företagstecknare</span>;
    case 'ASS': return <span>Taxeringsuppgifter</span>;
    case 'AUD': return <span>Beslut om revisor</span>;
    case 'BAC': return <span>Verksamhet/ändamål</span>;
    case 'BAN': return <span>Konkursinfo</span>;
    case 'BOA': return <span>Styrelse</span>;
    case 'CTX': return <span>F-skatt</span>;
    case 'DAM': return <span>Skuldsaldo A-mål</span>;
    case 'DAP': return <span>Betalningsanmärkning A-mål</span>;
    case 'DDT': return <span>Skuldsanering</span>;
    case 'DEA': return <span>Betalningsanmärkning E-mål</span>;
    case 'DEJ': return <span>Tredskodom</span>;
    case 'DEM': return <span>Skuldsaldo E-Mål</span>;
    case 'DEP': return <span>Betalningsanmärkning E-mål</span>;
    case 'DTA': return <span>Utmätningsförsök</span>;
    case 'EMC': return <span>Arb. avgift</span>;
    case 'FAX': return <span>Faxnummer</span>;
    case 'GAA': return <span>Koncernbokslut</span>;
    case 'INR': return <span>Delårsredovisning (PDF)</span>;
    case 'ISO': return <span>Certifikat</span>;
    case 'LIM': return <span>Limit</span>;
    case 'MER': return <span>Information om fusion</span>;
    case 'NAM': return <span>Företagsnamn</span>;
    case 'PHO': return <span>Telefonnummer</span>;
    case 'RAT': return <span>Rating</span>;
    case 'REC': return <span>Rekonstruktion</span>;
    case 'REG': return <span>Bolagets registreringsdatum</span>;
    case 'REP': return <span>Företrädaruppgifter</span>;
    case 'RES': return <span>Registrerat säte</span>;
    case 'SHC': return <span>Aktiekapital</span>;
    case 'STA': return <span>Status</span>;
    case 'VAT': return <span>Moms</span>;
  }
}
