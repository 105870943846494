import { Card, Row, Col } from 'react-bootstrap';
import Logo from 'client/components/Logo';

export function Centered (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <Row className="p-4">
      <Col sm={{span: 8, offset: 2}} md={{span: 6, offset: 3}} lg={{span: 4, offset: 4}} xl={{span: 3, offset: 5}}>
        {children}
      </Col>
    </Row>
  );
}

export function AuthWrapCard (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <div>
      <Logo className="mb-4 d-flex justify-content-center"/>
      <Card>
        {children}
      </Card>
    </div>
  );
}
