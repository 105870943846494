import React from 'react';
import {
  Accordion,
  ListGroup,
  ListGroupItem,
  Modal,
  Table,
} from 'react-bootstrap';
import { CreditReportWithRelationsRow } from 'client/creditReport/types';
import * as CreditReportFormatters from 'client/creditReport/CreditReportFormatters';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { UserAnchor } from 'client/user/UserFormatters';
import DateFormat from 'client/components/DateFormat';
import { AlertCircle } from 'react-feather';
import NumberFormat from 'client/components/NumberFormat';
import { BooleanFormatter } from 'client/components/MiscFormatters';
import classNames from 'classnames';
import AmountFormat from 'client/components/AmountFormat';
import { errorToMessage } from 'client/utils/errors';

interface CreditReportModalBodyProps {
  creditReport: CreditReportWithRelationsRow;
}

export default function CreditReportModalBody (props: CreditReportModalBodyProps) {
  const { creditReport } = props;
  return (
    <Modal.Body>
      <Table size="sm" bordered>
        <tbody>
          <tr>
            <th>Söknummer</th>
            <td>{creditReport.search_number}</td>
          </tr>
          <tr>
            <th>Datum</th>
            <td><DateFormat value={creditReport.status_updated_at} /></td>
          </tr>
          <tr>
            <th>Skapare</th>
            <td>
              {creditReport.creator ? <UserAnchor value={creditReport.creator} /> : '-'}
            </td>
          </tr>
        </tbody>
      </Table>
      {creditReport.status === 'missing' && (
        <Table size="sm" bordered>
          <tbody>
            <tr>
              <th>Fel</th>
              <td>
                Inget resultat hittades
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      {creditReport.status === 'error' && (
        <CreditReportErrorTable creditReport={creditReport} />
      )}
      {creditReport.status === 'result' && (
        <>
          <Table size="sm" bordered>
            <tbody>
              <tr>
                <th>Orgnummer</th>
                <td>
                  <CompanyFormatters.CompanyAnchor value={creditReport.org_number} />
                </td>
              </tr>
              <tr>
                <th>Företagsnamn</th>
                <td>{creditReport.company_name}</td>
              </tr>
              <tr>
                <th>Företagsstatus</th>
                <td>
                  <CompanyFormatters.StatusLabel value={creditReport.company_status} />
                </td>
              </tr>
              <tr>
                <th>Statusuppdatering</th>
                <td>
                  <DateFormat value={creditReport.company_status_date} format="YYYY-MM-DD" />
                </td>
              </tr>
              <tr>
                <th>Rating</th>
                <td>{creditReport.rating} / {creditReport.rating_text}</td>
              </tr>
              <tr>
                <th>Riskprognos</th>
                <td>
                  {creditReport.risk_prognosis !== null ? (
                    <NumberFormat value={creditReport.risk_prognosis} format="percentage" />
                  ) : ''}
                </td>
              </tr>
            </tbody>
          </Table>
          <ListGroup className="mb-3">
            {creditReport.commentary && creditReport.commentary.map(comment => (
              <ListGroupItem key={comment.code}>
                <CreditReportFormatters.CommentaryThumb value={comment.type} />{' '}
                <small>{comment.text}</small>
              </ListGroupItem>
            ))}
          </ListGroup>
          <Accordion>

            <Accordion.Item eventKey="bf">
              <Accordion.Header>
                Betalningsföreläggande
                {creditReport.antal_ansokan !== null && (creditReport.sum_ansokan ?? 0) > 0 && (
                  <AlertCircle className="text-danger ms-1" size={20} />
                )}
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <Table className="mb-0">
                  <tbody>
                    <tr>
                      <th>Summa</th>
                      <td>
                        {creditReport.sum_ansokan !== null && (
                          <NumberFormat value={creditReport.sum_ansokan} format="currency" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="border-bottom-0">Antal</th>
                      <td className="border-bottom-0">
                        <AmountFormat value={creditReport.antal_ansokan} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="kf">
              <Accordion.Header>
                Kronofogden
                {creditReport.kf_debt !== null && creditReport.kf_debt > 0 && (
                  <AlertCircle className="text-danger ms-1" size={20} />
                )}
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <Table className="mb-0">
                  <tbody>
                    <tr>
                      <th>Skuldsaldo</th>
                      <td>
                        {creditReport.kf_debt !== null && (
                          <NumberFormat value={creditReport.kf_debt} format="currency" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Senaste anmärkning</th>
                      <td>
                        {creditReport.kf_debt_date ? (
                          <DateFormat value={creditReport.kf_debt_date} />
                        ) : '-'}
                      </td>
                    </tr>
                    <tr>
                      <th>Antal/Summa A-mål</th>
                      <td>
                        <AmountFormat value={creditReport.kf_debt_nr_amal} />
                        {' / '}
                        <NumberFormat value={creditReport.kf_debt_sum_amal} format="currency" />
                      </td>
                    </tr>
                    <tr>
                      <th className="border-bottom-0">Antal/Summa E-mål</th>
                      <td className="border-bottom-0">
                        <AmountFormat value={creditReport.kf_debt_nr_amal} />
                        {' / '}
                        <NumberFormat value={creditReport.kf_debt_sum_emal} format="currency" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="anm">
              <Accordion.Header>
                Betalningsanmärkningar
                {creditReport.sum_anm !== null && creditReport.sum_anm > 0 && (
                  <AlertCircle className="text-danger ms-1" size={20} />
                )}
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <Table className="mb-0">
                  <tbody>
                    <tr>
                      <th>Summa</th>
                      <td>
                        {creditReport.sum_anm !== null && (
                          <NumberFormat value={creditReport.sum_anm} format="currency" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Antal/Summa A-mål</th>
                      <td>
                        <AmountFormat value={creditReport.antal_anm_amal} />
                        {' / '} 
                        <NumberFormat value={creditReport.sum_anm_amal} format="currency" />
                      </td>
                    </tr>
                    <tr>
                      <th className="border-bottom-0">Antal/Summa E-mål</th>
                      <td className="border-bottom-0">
                        <AmountFormat value={creditReport.antal_anm_emal} />
                        {' / '} 
                        <NumberFormat value={creditReport.sum_anm_emal} format="currency" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <CreditReportAccordionItemPayrollData creditReport={creditReport} />

          </Accordion>
        </>
      )}
    </Modal.Body>
  );
}

interface CreditReportAccordionItemProps {
  creditReport: CreditReportWithRelationsRow;
}

function CreditReportAccordionItemPayrollData (props: CreditReportAccordionItemProps) {
  const { creditReport } = props;

  const list = Array.isArray(creditReport.payroll_data) ? creditReport.payroll_data : [];
  return (
    <Accordion.Item eventKey="payroll_data">
      <Accordion.Header>
        Arbetsgivaravgifter
      </Accordion.Header>
      <Accordion.Body className="p-0">
        <Table size="sm" className="mb-0 text-center small">
          <thead>
            <tr>
              <th>Period</th>
              <th>Registrerat</th>
              <th>Summa</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 ? (
              <>
                {list.map((payroll, index) => (
                  <tr key={index}>
                    <td className={classNames({'border-bottom-0': index + 1 === list.length})}>
                      {payroll.payroll_date}
                    </td>
                    <td className={classNames({'border-bottom-0': index + 1 === list.length})}>
                      {payroll.register_date}
                    </td>
                    <td className={classNames({'border-bottom-0': index + 1 === list.length})}>
                      <NumberFormat value={payroll.fee} format="currency" />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={3} className="border-bottom-0">Det finns ingenting här.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CreditReportAccordionItemDirectors (props: CreditReportAccordionItemProps) {
  const { creditReport } = props;
  return (
    <Accordion.Item eventKey="directors">
      <Accordion.Header>
        Styrelse
      </Accordion.Header>
      <Accordion.Body>
        <ul className="list-unstyled mb-0">
          {creditReport.directors && creditReport.directors.map((director, index) => (
            <li key={index} className={classNames({'mb-3': (index + 1) < (creditReport?.directors?.length ?? 0)})}>
              <div><strong>{director.function}</strong></div>
              <div>{director.name}</div>
              <div className="small">{director.ssn}</div>
            </li>
          ))}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CreditReportAccordionItemFtax (props: CreditReportAccordionItemProps) {
  const { creditReport } = props;
  return (
    <Accordion.Item eventKey="ftax">
      <Accordion.Header>
        F-skatt
      </Accordion.Header>
      <Accordion.Body className="p-0">
        <Table className="mb-0">
          <tbody>
            <tr>
              <th>Moms-nr</th>
              <td>
                {creditReport.moms_nr}
              </td>
            </tr>
            <tr>
              <th>F-skatt start</th>
              <td>
                {creditReport.ftax_start_date !== null ? (
                  <DateFormat value={creditReport.ftax_start_date} format="YYYY-MM-DD" />
                ) : '-'}
              </td>
            </tr>
            <tr>
              <th className="border-bottom-0">F-skatt slut</th>
              <td className="border-bottom-0">
                {creditReport.ftax_end_date !== null ? (
                  <DateFormat value={creditReport.ftax_end_date} format="YYYY-MM-DD" />
                ) : '-'}
              </td>
            </tr>
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CreditReportAccordionItemMoms (props: CreditReportAccordionItemProps) {
  const { creditReport } = props;
  return (
    <Accordion.Item eventKey="moms">
      <Accordion.Header>
        Moms
      </Accordion.Header>
      <Accordion.Body className="p-0">
        <Table className="mb-0">
          <tbody>
            <tr>
              <th>Registrerad för moms?</th>
              <td>
                {creditReport.moms !== null && (
                  <BooleanFormatter value={creditReport.moms} />
                )}
              </td>
            </tr>
            <tr>
              <th>Moms-nr</th>
              <td>
                {creditReport.moms_nr}
              </td>
            </tr>
            <tr>
              <th className="border-bottom-0">Momsdatum start</th>
              <td className="border-bottom-0">
                {creditReport.moms_start_date !== null ? (
                  <DateFormat value={creditReport.moms_start_date} />
                ) : '-'}
              </td>
            </tr>
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}

interface CreditReportErrorTableProps {
  creditReport: CreditReportWithRelationsRow;
}

function CreditReportErrorTable (props: CreditReportErrorTableProps) {
  const { creditReport } = props;
  if (creditReport.status !== 'error') return null;
  const message = errorToMessage(creditReport.error as Error);
  return (
    <Table size="sm" bordered>
      <tbody>
        <tr>
          <th>Fel</th>
          <td>
            {message ? message : (
              <pre>{JSON.stringify(creditReport.error, null, 2)}</pre>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
