import React, { useMemo, useState } from 'react';
import { Formik, Form as FormikForm, Field, FormikHelpers, useFormikContext } from 'formik';
import { errorToMessage } from 'client/utils/errors';
import axios from 'client/axios';
import moment from 'moment';
import ErrorAlert from 'client/components/ErrorAlert';
import IdProvider from 'client/components/IdProvider';
import BlockSpinner from 'client/spinners/BlockSpinner';
import { Card, Form, InputGroup, Row, Col } from 'react-bootstrap';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as formUtils from 'client/utils/form';
import FormControlTextarea from 'client/form/FormControlTextarea';
import { useMutation } from '@tanstack/react-query';
import FortnoxAuthenticateButton from 'client/fortnox/FortnoxAuthenticateButton';
import {
  FortnoxTokenByAuthCodeButton,
  FortnoxTokenByRefreshCodeButton,
} from 'client/fortnox/FortnoxGetTokenButtons';
import { FortnoxToken, FortnoxTokenSetting } from 'client/fortnox/types';
import { Prompt } from 'react-router-dom';
import SaveButton from 'client/buttons/SaveButton';

export interface SettingsFormData {
  interest_rate: number;
  fortnox_access_token: FortnoxTokenSetting;
  fortnox_last_seen_invoice_finalpaydate: string;
  company_monitoring_last_updates_fetched_at: string;
}

interface SettingsFormProps {
  initialFormValues: SettingsFormData;
  isLoading: boolean;
}

const SettingsForm: React.FC<SettingsFormProps> = React.memo(function SettingsForm (props: SettingsFormProps) {
  const { isLoading, initialFormValues } = props;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  const updateSettingsMutation = useMutation<SettingsFormData, Error, Partial<SettingsFormData>>({
    mutationKey: ['UpdateSettingsMutation'],
    mutationFn: form => axios.patch('/api/settings', form).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Inställningarna uppdaterades');
    },
    onError: err => {
      setFormError(errorToMessage(err));
    },
  });

  const onSubmit = (form: SettingsFormData, helpers: FormikHelpers<SettingsFormData>) => {
    setFormError('');
    setIsSaving(true);
    const update = formUtils.changes(initialFormValues, form);
    return updateSettingsMutation.mutateAsync(update).then(values => {
      helpers.resetForm({values});
    }).finally(() => {
      setIsSaving(false);
    });
  };

  return (
    <Card>
      <BlockSpinner isLoading={isLoading} className="m-3" />
      {initialFormValues && (
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmit}
        >
          {formikBag => (
            <FormikForm>
              <Prompt
                when={formikBag.dirty}
                message="Är du säker på att du vill byta sida utan att spara formuläret?"
              />
              <Card.Body className="pb-1">
                <Row>
                  <Col md={6} sm={12}>
                    <IdProvider>
                      {id => (
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor={id}>
                            Kalkylränta
                          </Form.Label>
                          <InputGroup>
                            <Field
                              as={Form.Control}
                              id={id}
                              type="number"
                              name="interest_rate"
                              isInvalid={Boolean(formikBag.errors.interest_rate)}
                              required
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      )}
                    </IdProvider>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12}>
                    <IdProvider>
                      {id => (
                        <Form.Group className="mb-2">
                          <Form.Label htmlFor={id}>
                            Fortnox Access Token
                          </Form.Label>
                          <Field
                            as={FormControlTextarea}
                            id={id}
                            name="fortnox_access_token.token"
                            isInvalid={Boolean(formikBag.errors.fortnox_access_token?.token)}
                          />
                        </Form.Group>
                      )}
                    </IdProvider>
                    <div className="d-flex gap-2 mb-3 flex-wrap">
                      <SettingsFormFortnoxTokenButtonHandler>
                        {onNewAccessToken => (
                          <FortnoxTokenByAuthCodeButton onNewAccessToken={onNewAccessToken} />
                        )}
                      </SettingsFormFortnoxTokenButtonHandler>
                      <SettingsFormFortnoxTokenButtonHandler isForRefresh>
                        {(onNewAccessToken, refreshCode) => (
                          <FortnoxTokenByRefreshCodeButton
                            refreshCode={refreshCode}
                            onNewAccessToken={onNewAccessToken}
                          />
                        )}
                      </SettingsFormFortnoxTokenButtonHandler>
                    </div>
                    <Row>
                      <Col lg={6} md={12}>
                        <IdProvider>
                          {id => (
                            <Form.Group>
                              <Form.Label htmlFor={id}>
                                Access Token Utgångsdatum
                              </Form.Label>
                              <Field
                                as={Form.Control}
                                id={id}
                                name="fortnox_access_token.access_token_expires_at"
                                isInvalid={Boolean(formikBag.errors.fortnox_access_token?.access_token_expires_at)}
                              />
                            </Form.Group>
                          )}
                        </IdProvider>
                      </Col>
                      <Col lg={6} md={12}>
                        <IdProvider>
                          {id => (
                            <Form.Group>
                              <Form.Label htmlFor={id}>
                                Refresh Token Utgångsdatum
                              </Form.Label>
                              <Field
                                as={Form.Control}
                                id={id}
                                name="fortnox_access_token.refresh_token_expires_at"
                                isInvalid={Boolean(formikBag.errors.fortnox_access_token?.refresh_token_expires_at)}
                              />
                            </Form.Group>
                          )}
                        </IdProvider>
                      </Col>
                    </Row>
                    <div className="my-3">
                      <FortnoxAuthenticateButton />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <IdProvider>
                      {id => (
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor={id}>
                            Fortnox Faktura senast sedda betalningsdag
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            id={id}
                            name="fortnox_last_seen_invoice_finalpaydate"
                            placeholder="YYYY-MM-DD"
                            isInvalid={Boolean(formikBag.errors.fortnox_last_seen_invoice_finalpaydate)}
                          />
                          <Form.Text>Datumet upp till då alla betalda fakturor har hämtats. Används för att skapa kundundersökningar.</Form.Text>
                        </Form.Group>
                      )}
                    </IdProvider>
                  </Col>
                  <Col md={6} sm={12}>
                    <IdProvider>
                      {id => (
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor={id}>
                            Datum för senaste hämtning av företagsuppdateringar
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            id={id}
                            name="company_monitoring_last_updates_fetched_at"
                            placeholder="YYYY-MM-DD"
                            isInvalid={Boolean(formikBag.errors.company_monitoring_last_updates_fetched_at)}
                          />
                          <Form.Text>Senaste gången bevakningsuppdateringar från Creditsafe hämtades.</Form.Text>
                        </Form.Group>
                      )}
                    </IdProvider>
                  </Col>
                </Row>
                <ErrorAlert error={formError} />
              </Card.Body>
              <Card.Footer className="d-flex justify-content-start align-items-baseline py-3">
                <SaveButton
                  type="submit"
                  isLoading={isSaving}
                  disabled={!formikBag.isValid || formikBag.isSubmitting || !formikBag.dirty}
                >
                  Spara
                </SaveButton>
              </Card.Footer>
            </FormikForm>
          )}
        </Formik>
      )}
    </Card>
  );
});

export default SettingsForm;

interface SettingsFormFortnoxTokenButtonHandlerProps {
  children: (handler: (token: FortnoxToken) => void, refreshCode?: string) => React.ReactNode;
  isForRefresh?: boolean;
}

function SettingsFormFortnoxTokenButtonHandler (props: SettingsFormFortnoxTokenButtonHandlerProps) {
  const { isForRefresh = false, children } = props;

  const formikContext = useFormikContext<SettingsFormData>();

  const onNewAccessToken = (token: FortnoxToken) => {
    const obj: FortnoxTokenSetting = {
      token: JSON.stringify(token),
      // the subtraction of one minute is to give some leeway for delays between the issuing and now
      access_token_expires_at: moment().add(token.expires_in - 60, 'seconds').toISOString(),
    };
    if (!isForRefresh) {
      // if we are not refreshing but getting a new token, we have to bump the refresh token expiry too
      obj.refresh_token_expires_at = moment().add(45, 'days').subtract(60, 'seconds').toISOString();
    } else {
      obj.refresh_token_expires_at = formikContext.values.fortnox_access_token.refresh_token_expires_at;
    }
    formikContext.setValues(prev => ({...prev, fortnox_access_token: obj}));
  };

  const refreshCode = useMemo(() => {
    if (!formikContext.values.fortnox_access_token.token) return;
    try {
      const parsed = JSON.parse(formikContext.values.fortnox_access_token.token);
      return parsed.refresh_token ?? undefined;
    } catch (err) {
      return;
    }
  }, [formikContext.values.fortnox_access_token.token]);

  return (
    <>
      {children(onNewAccessToken, refreshCode)}
    </>
  );
}
