import React from 'react';
import { PdfView } from 'client/pdf/PdfView';
import { Container } from 'react-bootstrap';

export default function PdfPage () {
  return (
    <div className="clc-pdf-player">
      <Container fluid="sm" className="p-4">
        <div className="d-flex justify-content-center">
          <PdfView file={'/report.pdf'} />
        </div>
      </Container>
    </div>
  );
}
