import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import axios from 'client/axios';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as FormikFormControls from 'client/form/FormikFormControls';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import SaveButton from 'client/buttons/SaveButton';
import ErrorAlert from 'client/components/ErrorAlert';
import classNames from 'classnames';
import {
  IUser,
  UserCompanyMonitoredSettings as UserCompanyMonitoredSettingsFormData,
} from 'client/user/types';
import {Info} from 'react-feather';
import BlockSpinner from 'client/spinners/BlockSpinner';

interface UserCompanyMonitoredSettingsProps {
  className?: string;
  userId?: string;
}

const defaultInitialValues = {
  email_group: 'single',
  contact_frequency: 'daily',
  contact_frequency_weekly_anchor: '1',
  contact_frequency_monthly_anchor: 1,
};

export default function UserCompanyMonitoredSettings (props: UserCompanyMonitoredSettingsProps) {
  const { className, userId = 'me' } = props;

  const queryClient = useQueryClient();

  const query = useQuery<IUser['company_monitored_settings']>({
    queryKey: [`/api/user_company_monitored/${userId}/settings`],
  });

  const mutation = useMutation<UserCompanyMonitoredSettingsFormData, Error, UserCompanyMonitoredSettingsFormData>({
    mutationFn: form => axios.put('/api/user_company_monitored/me/settings', formToUpdate(form)).then(r => r.data),
    onSuccess: settings => {
      requestCallbacks.onSuccess('Kontaktinställningarna har uppdaterats');
      queryClient.setQueryData([`/api/user_company_monitored/${userId}/settings`], settings);
    },
  });

  const initialValues = {...defaultInitialValues, ...(query.data ?? {})};

  return (
    <Card className={classNames(className)}>
      <BlockSpinner isLoading={query.isLoading} />
      {query.isSuccess && (
        <UserCompanyMonitoredSettingsForm
          initialValues={initialValues}
          onSave={mutation.mutateAsync}
          error={mutation.error}
        />
      )}
    </Card>
  );
}

interface UserCompanyMonitoredSettingsFormProps {
  initialValues: UserCompanyMonitoredSettingsFormData;
  error: null | Error;
  onSave: (form: UserCompanyMonitoredSettingsFormData) => Promise<any>;
}

function UserCompanyMonitoredSettingsForm (props: UserCompanyMonitoredSettingsFormProps) {
  const { onSave, error, initialValues } = props;

  const onSubmit = async (form: UserCompanyMonitoredSettingsFormData, helpers: FormikHelpers<UserCompanyMonitoredSettingsFormData>) => {
    await onSave(form);
    helpers.resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {formProps => (
        <FormikForm>
          <div>
            <Row className="p-3 pt-0">
              <Col md={6} lg={8}>
                <Row>
                  <Col lg={formProps.values.contact_frequency === 'daily' ? 12 : 6}>
                    <FormikFormControls.Select
                      label="Kontaktfrekvens"
                      name="contact_frequency"
                      required
                    >
                      <option value="daily">Dagligen</option>
                      <option value="weekly">Per vecka</option>
                      <option value="monthly">Per månad</option>
                    </FormikFormControls.Select>
                  </Col>
                  <Col lg={6}>
                    {formProps.values.contact_frequency === 'weekly' && (
                      <FormikFormControls.Select
                        label="Veckodag"
                        name="contact_frequency_weekly_anchor"
                        required
                      >
                        <option value="">Ange en veckodag</option>
                        <option value="1">Måndag</option>
                        <option value="2">Tisdag</option>
                        <option value="3">Onsdag</option>
                        <option value="4">Torsdag</option>
                        <option value="5">Fredag</option>
                        <option value="6">Lördag</option>
                        <option value="0">Söndag</option>
                      </FormikFormControls.Select>
                    )}
                    {formProps.values.contact_frequency === 'monthly' && (
                      <FormikFormControls.Input
                        label="Månadsdatum"
                        type="number"
                        name="contact_frequency_monthly_anchor"
                        required
                        step={1}
                        min={1}
                        max={28}
                        placeholder="1-28"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6} lg={4}>
                <FormikFormControls.Select
                  label="E-postutskick"
                  name="email_group"
                  required
                >
                  <option value="single">Som ett samlingsmail</option>
                  <option value="org_number">Ett e-postmeddelande per företag</option>
                </FormikFormControls.Select>
              </Col>
            </Row>
            <div className="d-flex justify-content-start align-items-center p-3 pt-0 flex-wrap gap-2">
              <SaveButton
                size="sm"
                type="submit"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || !Object.keys(formProps.touched).length || formProps.isSubmitting}
                buttonLabel="Spara"
              />
              <ContactFrequencyDescription values={formProps.values} />
            </div>
          </div>
          <ErrorAlert className="m-3 mt-0" error={error} />
        </FormikForm>
      )}
    </Formik>
  );
}

interface ContactFrequencyDescriptionProps {
  values: UserCompanyMonitoredSettingsFormData;
}

function ContactFrequencyDescription (props: ContactFrequencyDescriptionProps) {
  const { values } = props;
  const {
    contact_frequency:frequency,
    contact_frequency_weekly_anchor:weekly_anchor,
    contact_frequency_monthly_anchor:monthly_anchor,
    email_group:group = 'single',
  } = values;
  if (!frequency || !group) return null;
  if (frequency === 'weekly' && !weekly_anchor) return null;
  if (frequency === 'monthly' && !monthly_anchor) return null;
  return (
    <InfoAlert>
      {' '}{group === 'single' && <>Ett enda e-postmeddelande med alla uppdateringarna </>}
      {' '}{group === 'org_number' && <>Ett e-postmeddelande per (uppdaterat) företag </>}
      {frequency === 'daily' && 'kommer att skickas max en gång varje dag.'}
      {frequency === 'weekly' && `kommer att skickas max en gång varje vecka på ${dayOfWeek(weekly_anchor)}.`}
      {frequency === 'monthly' && `kommer att skickas max en gång varje månad den ${dateOfMonth(monthly_anchor)}.`}
    </InfoAlert>
  );
}

function dateOfMonth (dateOfMonth?: any): string {
  if (dateOfMonth < 3) return dateOfMonth + ':a';
  return dateOfMonth + ':e';
}

function dayOfWeek (dayOfWeek?: any): string {
  switch (String(dayOfWeek)) {
    default: return '';
    case '0': return 'söndagar';
    case '1': return 'måndagar';
    case '2': return 'tisdagar';
    case '3': return 'onsdagar';
    case '4': return 'torsdagar';
    case '5': return 'fredagar';
    case '6': return 'lördagar';
  }
}

function InfoAlert (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <Alert variant="secondary" className="small m-0 py-1 p-2 d-flex align-items-center gap-1">
      <Info  size={18} />
      {children}
    </Alert>
  );
}

function formToUpdate (form: UserCompanyMonitoredSettingsFormData): any {
  const {
    contact_frequency,
    contact_frequency_weekly_anchor,
    contact_frequency_monthly_anchor,
    ...restOfProps
  } = form;
  const result: any = {contact_frequency, ...restOfProps};
  if (contact_frequency === 'monthly') result.contact_frequency_monthly_anchor = contact_frequency_monthly_anchor ?? 1;
  if (contact_frequency === 'weekly') result.contact_frequency_weekly_anchor = parseInt((contact_frequency_weekly_anchor as string) ?? '1', 10) || 1;
  return result;
}
