import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import PageHeader from 'client/components/PageHeader';
import { Alert, Badge, Card } from 'react-bootstrap';
import classNames from 'classnames';
import { UserCompanyMonitoredRowWithRelations } from 'client/userCompanyMonitored/types';
import RefreshButton from 'client/buttons/RefreshButton';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { useMutation, useQuery } from '@tanstack/react-query';
import CompanySearch from 'client/components/CompanySearch';
import useRemount from 'client/hooks/useRemount';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import TableVirtual from 'client/table/TableVirtual';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import LoadingButton from 'client/buttons/LoadingButton';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import axios from 'client/axios';
import {ColumnDefinition} from 'client/table/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import Pluralizer from 'client/components/Pluralizer';
import UserCompanyMonitoredSettings from 'client/userCompanyMonitored/UserCompanyMonitoredSettings';

export default function UserCompanyMonitoredPage () {
  return (
    <Container className="p-4" fluid="sm">
      <Helmet>
        <title>Mina företagsbevakningar</title>
      </Helmet>
      <PageHeader>
        Mina företagsbevakningar
      </PageHeader>
      <UserCompanyMonitoredSettings className="mb-4" />
      <UserCompanyMonitoredTableCard />
    </Container>
  );
}

interface CompanyMonitoringCardProps {
  className?: string;
}

interface CustomerCompanyMonitoredQueryResponse {
  rows: UserCompanyMonitoredRowWithRelations[];
  limit: number;
  email: string;
}

interface TableRow extends UserCompanyMonitoredRowWithRelations {
  id: string;
}

function UserCompanyMonitoredTableCard (props: CompanyMonitoringCardProps) {
  const { className } = props;
  const [orgNumber, setOrgNumber] = useState<string>('');

  const orgNumberRemount = useRemount();

  const query = useQuery<CustomerCompanyMonitoredQueryResponse, Error>({
    queryKey: ['/api/user_company_monitored/me'],
  });

  const data = query.data;

  const rows: TableRow[] = useMemo(() => {
    if (!query.data?.rows) return [];
    return query.data.rows.map(row => ({...row, id: row.org_number}));
  }, [query.data?.rows]);

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: orgNumber => axios.delete(`/api/user_company_monitored/me/company/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      query.refetch();
    },
  });

  const createMutation = useMutation<unknown, Error, string>({
    mutationFn: orgNumber => axios.put(`/api/user_company_monitored/me/company/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har lagts till');
      setOrgNumber('');
      orgNumberRemount.remount();
      query.refetch();
    },
  });

  const onSubmitCreate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    return createMutation.mutateAsync(orgNumber);
  };
  
  const orgNumberErrorExists = useMemo(() => rows.some(item => {
    if (!orgNumber) return false;
    return item.org_number === orgNumber;
  }), [rows, orgNumber]);

  const columns = columnFactory(deleteMutation.mutateAsync);

  const limitReached = data && rows ? data.limit - rows.length <= 0 : false;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 p-3">
        <Card.Title as="h6" className="mb-0 p-0 d-flex flex-wrap gap-2 justify-content-between align-items-center">
          <span>
            Bevakade företag{' '}
            {data && rows && (
              <Badge bg="secondary">{Math.max(0, data.limit - rows.length)}/{data.limit} företag kvar</Badge>
            )}
          </span>
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            size="sm"
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      {query.isSuccess && data && limitReached && (
        <Card.Body className="border border-bottom-0">
          <Alert className="mt-3 mb-1">
            Maxgränsen för hur många företag som går att bevaka är nådd.
          </Alert>
        </Card.Body>
      )}
      <Card.Body className="border border-bottom-0">
        <form className="d-flex gap-2 flex-wrap" onSubmit={onSubmitCreate}>
          {orgNumberRemount.mounted && (
            <CompanySearch
              className="w-auto flex-grow-1"
              onSelect={setOrgNumber}
              defaultInputValue={orgNumber}
            />
          )}
          <LoadingButton
            type="submit"
            disabled={!orgNumber || createMutation.isPending || orgNumberErrorExists || limitReached}
            isLoading={createMutation.isPending}
          >
            Lägg till bevakning
          </LoadingButton>
        </form>
      </Card.Body>
      <TableSpinningOverlay isLoading={query.isRefetching}>
        <div className="table-responsive">
          <TableVirtual
            isInitialLoading={query.isLoading}
            className="mb-0 border align-middle"
            columns={columns}
            rows={rows}
            emptyMessage="Inga företag är bevakade"
          />
        </div>
      </TableSpinningOverlay>
      <Card.Footer className="border border-top-0 py-3">
        Totalt {rows.length}{' '}
        <Pluralizer
          count={rows.length}
          zero="bevakade företag"
          one="bevakat företag"
          more="bevakade företag"
        />
      </Card.Footer>
    </Card>
  );
}

type ColumnFactory = (deleteMutateAsync: (orgNumber: string) => Promise<any>) => ColumnDefinition[];

const columnFactory: ColumnFactory = deleteMutateAsync => [
  columnDefs.cell(['name', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic} />
  )),
  columnDefs.cell(['status', 'Företagsstatus'], props => (
    <CompanyFormatters.StatusLabel value={props.row.cs_basic.status_text_high} />
  )),
  columnDefs.date(['created_at', 'Bevakat']),
  columnDefs.cell(['settings', 'Inställningar'], props => (
    <>
      {props.row.settings === null ? '-' : 'Specifika'}
    </>
  )),
  columnDefs.actions(props => (
    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
      <ConfirmDeleteButton
        size="sm"
        className="px-1"
        onConfirmedDelete={() => deleteMutateAsync(props.row.org_number)}
        confirmMessage="Är du säker på att du vill ta bort bevakningen av detta företag?"
        buttonTitle="Ta bort bevakningen"
        buttonLabel=""
      />
    </div>
  )),
];
