import React, { useMemo, useCallback, useState, useEffect } from 'react';
import * as commonColumnDefinitions from 'client/table/commonColumnDefinitions';
import CompanyTableBatchForm from 'client/company/CompanyTableBatchForm';
import CompanyTableFilterForm from 'client/company/CompanyTableFilterForm';
import ErrorAlert from 'client/components/ErrorAlert';
import PageHeader from 'client/components/PageHeader';
import RoleGuard from 'client/guards/RoleGuard';
import TableVirtual from 'client/table/TableVirtual';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import { Card, Container } from 'react-bootstrap';
import { ColumnDefinition } from 'client/table/types';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import * as CsFormatters from 'client/cs/CsFormatters';
import { Helmet } from 'react-helmet';
import { ICsBasicExtended } from 'client/cs/types';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import { keepPreviousData, useMutationState, useQuery } from '@tanstack/react-query';
import { last } from 'lodash';
import { TableProvider } from 'client/contexts/TableContext';

export interface CompanyTableFilterParams {
  search_term?: string;
  nbr_employees_interval?: string;
  sni_code?: string;
  legalgroup_code?: string;
}

export interface CsBasicExtendedRow extends ICsBasicExtended {
  id: string;
}

interface ListData {
  rows: ICsBasicExtended[];
  total_rows: number;
}

const defaultFilterParams = {
  legalgroup_code: 'AB',
};
const defaultState = {
  orderBy: 'org_number',
  orderDirection: OrderDirection.ASC,
  ...defaultFilterParams,
};

export default React.memo(function CompanyTablePage () {
  const {
    setStateMap,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    tableColumns,
    setTableColumns,
    params,
  } = useTableState({
    defaultState,
    defaultTableColumns,
    rowsPerPageMax: 500,
  });

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/companies/list', params],
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    const count = listQuery.data?.total_rows ?? 0;
    tablePagination.onChangeTotalCountOfRows(count);
  }, [listQuery.data?.total_rows]);

  const onRefetch = useCallback(() => {
    listQuery.refetch();
  }, [listQuery.refetch]);

  const batchMutationError = last(useMutationState({ filters: { mutationKey: ['CompanyTableBatchForm'] } }))?.error;
  const error = listQuery.error || batchMutationError;

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const rows = useMemo(() => {
    const rows = listQuery.data?.rows ?? [];
    return rows.map(obj => ({...obj, id: obj.org_number}));
  }, [listQuery.data?.rows]);

  const allIds = useMemo(() => rows.map(item => item.org_number), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);

  return (
    <Container fluid className="p-4">
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Alla företag</title>
        </Helmet>
        <PageHeader>Alla företag</PageHeader>
        <ErrorAlert className="my-3" error={error} />
        <Card>
          <Card.Header className="p-3">
            <CompanyTableFilterForm
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterParams={filterParams}
              setFilterParams={setStateMap}
              onRefetch={onRefetch}
              onReset={filterReset}
              columnDefinitions={columnDefinitions}
              columnOrder={tableColumns}
              setColumnOrder={setTableColumns}
            />
          </Card.Header>
          <RoleGuard role={['admin', 'coordinator']}>
            <CompanyTableBatchForm
              tableSelectRows={tableSelectRows}
              columnOrder={tableColumns}
              columnDefinitions={columnDefinitions}
            />
          </RoleGuard>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={tableColumns}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </Container>
  );
});

const defaultTableColumns = [
  'select',
  'org_number',
  'company_name',
  'registered_town',
  'sni_code',
  'legalgroup_text',
  'status_text_high',
  'registration_date',
  'nbr_employees_interval',
  'created_at',
  'actions',
];

const columnSortable = [
  'org_number',
  'company_name',
  'created_at',
];

type ColumnDefinitionFactory = () => ColumnDefinition<CsBasicExtendedRow>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  commonColumnDefinitions.select(),
  commonColumnDefinitions.cell(['org_number', 'Orgnr'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.org_number} />
  )),
  commonColumnDefinitions.simple(['company_name', 'Bolagsnamn']),
  commonColumnDefinitions.simple(['registered_town', 'Sätets adress, ort']),
  commonColumnDefinitions.simple(['registered_co_street', 'Sätets adress, C/O-adress']),
  commonColumnDefinitions.simple(['registered_street', 'Sätets adress, gatuadress']),
  commonColumnDefinitions.simple(['registered_zipcode', 'Sätets adress, postnummer']),
  commonColumnDefinitions.simple(['registered_community_code', 'Sätets adress, kommunkod']),
  commonColumnDefinitions.simple(['registered_community_name', 'Sätets adress, kommun']),
  commonColumnDefinitions.simple(['registered_region_code', 'Sätets adress, länskod']),
  commonColumnDefinitions.simple(['registered_region_name', 'Sätets adress, län']),
  commonColumnDefinitions.simple(['post_co_street', 'Utdelningsadress, C/O-adress']),
  commonColumnDefinitions.simple(['post_street', 'Utdelningsadress, gatuadress']),
  commonColumnDefinitions.simple(['post_zipcode', 'Utdelningsadress, postnummer']),
  commonColumnDefinitions.simple(['post_town', 'Utdelningsadress, ort']),
  commonColumnDefinitions.simple(['post_community_code', 'Utdelningsadress, kommunkod']),
  commonColumnDefinitions.simple(['post_community_name', 'Utdelningsadress, kommun']),
  commonColumnDefinitions.simple(['post_region_code', 'Utdelningsadress, länskod']),
  commonColumnDefinitions.simple(['post_region_name', 'Utdelningsadress, län']),
  commonColumnDefinitions.simple(['visiting_street', 'Besöksadress, gatuadress']),
  commonColumnDefinitions.simple(['visiting_zipcode', 'Besökadress, postnummer']),
  commonColumnDefinitions.simple(['visiting_town', 'Besöksadress, ort']),
  commonColumnDefinitions.simple(['visiting_community_code', 'Besöksadress, kommunkod']),
  commonColumnDefinitions.simple(['visiting_community_name', 'Besöksadress, kommun']),
  commonColumnDefinitions.simple(['visiting_region_code', 'Besöksadress, länskod']),
  commonColumnDefinitions.simple(['visiting_region_name', 'Besöksadress, län']),
  commonColumnDefinitions.date(['status_date', 'Datum för senaste status kod']),
  commonColumnDefinitions.simple(['status_code', 'Bolagets statuskod (kod)']),
  commonColumnDefinitions.cell(['status_text_high', 'Bolagets status (text)'], props => (
    <CompanyFormatters.StatusLabel value={props.row.status_text_high} />
  )),
  commonColumnDefinitions.simple(['status_text_detailed', 'Bolagets status (detaljerad)']),

  commonColumnDefinitions.simple(['complete_ef_pnr', 'Fullständigt personnummer för EF']),

  commonColumnDefinitions.simple(['phone_number', 'Telefonnummer']),
  commonColumnDefinitions.simple(['fax_number', 'Faxnummer']),
  commonColumnDefinitions.simple(['orgnr_status_affected', 'Orgnr för bolag som händelse avser, ex. fusion']),
  commonColumnDefinitions.date(['company_formed_date', 'Datum då bolagets bildades']),
  commonColumnDefinitions.date(['registration_date', 'Registreringsdatum']),
  commonColumnDefinitions.date(['deregistration_date', 'Avregistreringsdatum']),
  commonColumnDefinitions.simple(['legalgroup_code', 'Bolagsform (kod)']),
  commonColumnDefinitions.simple(['legalgroup_text', 'Bolagsform (text)']),
  commonColumnDefinitions.cell(['f_tax_reg', 'F-skattstatus'], props => (
    <CsFormatters.CsActive value={props.row.f_tax_reg} />
  )),
  commonColumnDefinitions.cell(['f_tax_yes_no', 'F-skatt'], props => (
    <CsFormatters.CsYesNo value={props.row.f_tax_yes_no} />
  )),
  commonColumnDefinitions.cell(['f_tax_reg_spec', 'F-skatthistorik'], props => (
    <CompanyFormatters.CompanyFTaxRegSpec value={props.row.f_tax_dereg_reason_code} />
  )),
  commonColumnDefinitions.date(['f_tax_start_date', 'Startdatum F-Skatt']),
  commonColumnDefinitions.date(['f_tax_end_date', 'Slutdatum F-Skatt']),
  commonColumnDefinitions.simple(['f_tax_dereg_reason_code', 'Orsak till avregistrering av F-skatt']),
  commonColumnDefinitions.cell(['moms_reg', 'Momsstatus'], props => (
    <CsFormatters.CsActive value={props.row.moms_reg} />
  )),
  commonColumnDefinitions.cell(['moms_yes_no', 'Moms'], props => (
    <CsFormatters.CsYesNo value={props.row.moms_yes_no} />
  )),
  commonColumnDefinitions.date(['moms_start_date', 'Startdatum moms']),
  commonColumnDefinitions.date(['moms_end_date', 'Slutdatum moms']),
  commonColumnDefinitions.cell(['sni_code', 'SNI-kod'], props => (
    <CompanyFormatters.CompanySniCode value={props.row} />
  )),
  commonColumnDefinitions.simple(['sni_text', 'SNI-text']),
  commonColumnDefinitions.cell(['accountant_reservation', 'Revisorsförbehåll'], props => (
    <CsFormatters.CsYesNo value={props.row.accountant_reservation} />
  )),
  commonColumnDefinitions.date(['date_accountant_reservation', 'Datum för revisorsförbehåll']),
  commonColumnDefinitions.simple(['number_of_units', 'Antal arbetsställen']),
  commonColumnDefinitions.simple(['top_director_name', 'Högsta befattningshavares namn']),
  commonColumnDefinitions.simple(['top_director_function', 'Högsta befattningshavares funktion']),
  commonColumnDefinitions.simple(['turnover_interval', 'Omsättningsintervall']),
  commonColumnDefinitions.simple(['nbr_employees_interval', 'Anställda']),
  commonColumnDefinitions.date(['created_at', 'Skapad']),
  commonColumnDefinitions.date(['updated_at', 'Uppdaterad']),

  commonColumnDefinitions.actions(),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? commonColumnDefinitions.sortable(obj) : obj);
