import React from 'react';
import { Button, Form, FormControlProps, InputGroup } from 'react-bootstrap';
import { generate } from 'generate-password-browser';

const pattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$';

export const passwordRegexp = new RegExp(pattern);

interface PasswordFormControlProps extends FormControlProps {
  isOwnPassword?: boolean;
}

// this should be used for all instances where a password is required to be SET
// it should NOT be used for the login password field
export default function PasswordFormControl (props: PasswordFormControlProps) {
  const { isOwnPassword = false, ...restOfProps } = props;

  return (
    <InputGroup className="w-auto flex-grow-1">
      <Form.Control
        {...restOfProps}
        type={isOwnPassword ? 'password' : 'text'}
        minLength={8}
        pattern={pattern}
        autoComplete="new-password"
      />
    </InputGroup>
  );
}

export function PasswordFormText () {
  return (
    <Form.Text>
      Lösenordet måste vara minst 8 tecken långt och bestå av små och stora bokstäver, siffror och minst ett specialtecken.
    </Form.Text>
  );
}

interface PasswordRandomButtonProps {
  onGenerated: (password: string) => void;
}

export function PasswordRandomButton (props: PasswordRandomButtonProps) {
  const { onGenerated } = props;

  const onClick = () => {
    const password = generate({
      length: 12,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      strict: true,
      excludeSimilarCharacters: true,
    });
    onGenerated(password);
  };

  return (
    <Button variant="outline-primary" onClick={onClick}>
      Slumpgenerera
    </Button>
  );
}


