import React, { useState, useEffect } from 'react';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import ReportQueueEditInnerPage from 'client/reportQueue/ReportQueueEditInnerPage';
import BlockSpinner from 'client/spinners/BlockSpinner';
import { IValuationReportFormData } from 'client/valuationReport/types';
import { IValuationReportsGetReportQueueInfoData } from 'client/valuationReport/types';
import ValuationReportForm from 'client/valuationReport/ValuationReportForm';
import { IReportQueue } from 'client/reportQueue/types';
import { StatusLabel } from 'client/reportQueue/ReportQueueFormatters';
import { CardHeaderObject } from 'client/card/CardHelpers';
import { Card , Container} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import PageHeader from 'client/components/PageHeader';

interface IValuationReportUpdateVars {
  reportQueueId: string;
  form: IValuationReportFormData;
}

interface IValuationReportEditPageParams {
  reportQueueId: string;
  tab?: string;
}

interface IValuationReportEditPage { }
const ValuationReportEditPage: React.FC<IValuationReportEditPage> = React.memo(function ValuationReportEditPage () {
  const { reportQueueId } = useParams<IValuationReportEditPageParams>();

  const [form, setForm] = useState<IValuationReportFormData | null>(null);

  const getReportQueueInfoData = useQuery<IValuationReportsGetReportQueueInfoData>({
    queryKey: ['ValuationReportEditPage', reportQueueId],
    queryFn: () => axios.get(`/api/valuation_reports/report_queue/${reportQueueId}`).then(r => r.data),
    placeholderData: keepPreviousData,
  });

  const data = getReportQueueInfoData.data;

  useEffect(() => {
    if (data) setForm(data.reportQueue);
  }, [data]);

  const updateValuationReportMutation = useMutation<IReportQueue, Error, IValuationReportUpdateVars>({
    mutationKey: ['UpdateValuationReport'],
    mutationFn: vars => axios.put(`/api/valuation_reports/report_queue/${vars.reportQueueId}`, vars.form).then(result => result.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Företagsvärderingen har uppdaterats');
    },
  });

  const onSubmitUpdateForm = () => {
    const vars = {reportQueueId, form: form as IValuationReportFormData};
    updateValuationReportMutation.mutate(vars);
  };

  return (
    <Container fluid="md" className="p-4">
      <Helmet>
        <title>Redigera köad företagsvärdering "{reportQueueId}"</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'Rapportkö', url: '/report_queue/new'}, {title: '#' + reportQueueId}]}>
        Redigera köad företagsvärdering
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={reportQueueId}
          extra={form?.report_org_number}
          status={<>
            {data?.reportQueue && (
              <StatusLabel value={data.reportQueue.status} done={data.reportQueue.is_done} />
            )}
          </>}
        />
        <ErrorAlert className="m-3" error={getReportQueueInfoData.error} />
        <BlockSpinner isLoading={getReportQueueInfoData.isLoading} className="m-3" />
        {getReportQueueInfoData.isSuccess && data && form && (
          <ReportQueueEditInnerPage
            data={getReportQueueInfoData.data}
            isRefetching={getReportQueueInfoData.isRefetching}
            Form={
              <ValuationReportForm
                isEditForm
                data={data}
                form={form as IValuationReportFormData}
                setForm={setForm as unknown as React.Dispatch<React.SetStateAction<IValuationReportFormData>>}
                onSubmitForm={onSubmitUpdateForm}
                submitError={updateValuationReportMutation.error}
                submitIsLoading={updateValuationReportMutation.isPending}
              />
            }
          />
        )}
      </Card>
    </Container>
  );
});
export default ValuationReportEditPage;
